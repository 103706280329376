<template>
  <div class="expande-horizontal column pt-0">
    <v-flex xs12>
      <div class="windows-style expande-horizontal fonte column">
        <transition name="slide-fade">
          <v-list-item class="pa-0 ma-0">
            <v-avatar
              @click="backToList"
              style="cursor: pointer;"
              size="27"
              :color="$theme.primary"
              class="mr-2"
              icon
            >
              <v-icon color="#f2f2f2">mdi-close</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ get_cliente.new ? "Novo cliente" : get_cliente.nome }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-mini">
                Gerencie os detalhes abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </transition>
      </div>
    </v-flex>
    <div
      style="min-height: 80vh; max-height: 80vh;"
      class="windows-style-content expande-horizontal wrap"
    >
      <v-tabs
        v-model="tabs"
        :background-color="$theme.primary"
        dark
        class="fonte"
      >
        <v-tab>Info do Cliente</v-tab>
        <v-tab @click="listClientSales">Compras do cliente</v-tab>
      </v-tabs>
      <v-flex v-if="tabs == 0" xs12>
        <v-form ref="form">
          <v-flex class="px-2" xs12>
            <v-text-field
              v-model="get_cliente.nome"
              filled
              label="Nome"
              clearable
              :color="$theme.primary"
              :rules="[v => !!v || 'Preencha este campo']"
              placeholder="ex: Roberto"
            ></v-text-field>
          </v-flex>

          <v-flex class="px-2" xs12>
            <v-text-field
              v-model="get_cliente.email"
              clearable
              filled
              :color="$theme.primary"
              label="Email"
              placeholder="ex: meuemail@gmail.com"
            ></v-text-field>
          </v-flex>

          <div class="expande-horizontal wrap">
            <v-flex class="px-2" xs12 md4>
              <v-text-field
                v-model="get_cliente.cpf"
                label="CPF"
                v-mask="['###.###.###-##']"
                filled
                clearable
                :color="$theme.primary"
                placeholder="ex: 000.000.000-00"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-2" xs12 md4>
              <v-text-field
                v-model="get_cliente.telefone"
                label="Celular"
                v-mask="['(##) # #### ####']"
                clearable
                filled
                :color="$theme.primary"
                placeholder="ex: (96) 9 8419-6827"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-2" xs12 md4>
              <v-text-field
                v-model="get_cliente.data_nascimento"
                label="Nascimento"
                filled
                v-mask="['##/##/####']"
                clearable
                :color="$theme.primary"
                placeholder="ex: 12/12/1984"
              ></v-text-field>
            </v-flex>
          </div>
          <div class="expande-horizontal">
            <v-flex class="px-2" xs12>
              <v-textarea
                v-model="get_cliente.obs"
                label="Anotações sobre o cliente"
                filled
                clearable
                :color="$theme.primary"
              ></v-textarea>
            </v-flex>
          </div>
        </v-form>
      </v-flex>
      <!-- Compras do cliente -->
      <v-flex v-if="tabs == 1" xs12>
        <div class="expande-horizontal wrap">
          <h2 class="fonte-bold ml-3">
            O Cliente realizou {{ sales.length }} compras.
          </h2>
          <div
            style="align-items: flex-start !important; max-height: 67vh; overflow: auto;"
            class="expande-horizontal px-3 wrap"
            v-if="sales.length > 0"
          >
            <v-flex xs12 md3 class="mr-3" v-for="sale in sales" :key="sale._id">
              <SaleItem :kanban="sale" />
            </v-flex>
          </div>

          <div class="expande-horizontal centraliza" v-else>
            <span class="my-12 fonte"
              >Este cliente ainda não realizou nenhuma compra</span
            >
          </div>
        </div>
      </v-flex>
    </div>

    <div class="expande-horizontal py-1 windows-style-bottom">
      <v-btn large dark @click="valida_form" :color="$theme.primary">
        <span class="fonte"> Salvar </span>
      </v-btn>
      <!-- <v-btn
        v-if="get_cliente._id"
        color="red"
        class="ml-3"
        @click="excluir_cliente_dialog(get_cliente)"
        text
        >Excluir</v-btn
      > -->
    </div>
  </div>
</template>

<script>
import SaleItem from "@/apps/GestorDePedido/components/SaleItem.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    SaleItem
  },
  computed: {
    ...mapGetters(["get_cliente", "getLoggedUser"])
  },
  data() {
    return {
      tabs: 0,
      sales: []
    };
  },
  methods: {
    ...mapActions([
      "criar_cliente",
      "atualizar_cliente",
      "fecha_modal_view_cliente",
      "enableLoading",
      "disableLoading"
    ]),
    listClientSales() {
      this.enableLoading();
      this.$run("clientes/list-client-sales", {
        _id: this.get_cliente._id
      }).then(res => {
        this.disableLoading();
        this.sales = res.data;
      });
    },
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_cliente._id ? this.atualizar_cliente() : this.criar_cliente();
      }
    },
    backToList() {
      this.$store.commit("set_cliente", {});
      this.$forceUpdate();
    }
  }
};
</script>
